import { Link } from "gatsby"
import React from "react"
import Button from "react-bootstrap/Button"
import Card from "react-bootstrap/Card"
import Image from "react-bootstrap/Image"

const CourseLink = props => {
  return (
    <Card
      className="card-container m-1 mb-3"
      as={Link}
      to={props.show ? props.url : "#"}
    >
      <Card.Header className="d-flex justify-content-between">
        <Image src={props.image} alt="" />
        {props.soon && (
          <div>
            <Button
              style={{
                fontSize: "13px",
              }}
              size="sm"
              variant="outline-warning btn-upcomming"
            >
              Bientôt
            </Button>
          </div>
        )}
        {!props.soon && (
          <div>
            <Button
              style={{
                fontSize: "13px",
              }}
              size="sm"
              variant="outline-info btn-available"
            >
              Disponible
            </Button>
          </div>
        )}
      </Card.Header>
      <Card.Body className="pt-3">
        <Card.Title>
          <h4>{props.title}</h4>
        </Card.Title>
        <Card.Text>{props.description}</Card.Text>
      </Card.Body>
      <Card.Footer className="d-flex justify-content-between">
        <span
          style={{
            fontSize: "13px",
            color: "#333333",
          }}
        >
          {props.place}
        </span>
        <h6
          style={{
            fontSize: "13px",
            color: "#8b8989",
            fontWeight: "bold",
          }}
        >
          {props.subtitle}
        </h6>
      </Card.Footer>
    </Card>
  )
}

export default CourseLink
